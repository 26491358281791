<template>
  <div class="pay">
    <ShopNavBar title="领奖"></ShopNavBar>

    <div class="address">
      <van-cell title="收货地址" value="添加地址" is-link @click="tolink('address')" v-if="addressList.length == 0" />
      <van-cell title="收货地址" value="选择地址" is-link @click="show = true" v-else />
      <div class="row items-center q-mt-sm" v-if="addressList.length > 0">
        <q-icon name="room" size="26px" color="grey"></q-icon>
        <div class="col q-ml-sm">
          <div>{{ address.username }} {{ address.usertel }}</div>
          <div class="address-details">{{ address.sheng+address.shi+address.xian+address.address }}</div>
        </div>
      </div>
    </div>

    <div class="goods">
      <img :src="getimg(order.goodsModelImg)" class="goods-img" />
      <div class="goods-content">
        <div class="goods-title">{{ order.goodsName }}</div>
        <div class="goods-label q-mt-xs">规格：{{ order.goodsModelName }}</div>
        <div class="goods-price q-mt-xs">￥{{ order.goodsPrice }}</div>
      </div>
    </div>

    <div class="btn" @click="cash" v-if="order.stateLucky == 2">领奖</div>
    <div class="btn disable-btn" v-else>{{ order.stateLuckyName }}</div>

    <!-- 选择地址 -->
    <q-dialog v-model="show" position="bottom">
      <div class="dialog">
        <div class="dialog-head">
          <div class="text-grey" @click="tolink('address')">添加新地址</div>
          <q-icon name="close" color="grey" size="18px" @click="show = false"></q-icon>
        </div>
        <div class="q-mt-sm">
          <div class="row items-center q-mt-sm" v-for="item in addressList" :key="item.id" @click="selectAddress(item)">
            <q-icon name="room" size="26px" color="grey"></q-icon>
            <div class="col q-my-xs">
              <div>{{ item.username }} {{ item.usertel }}</div>
              <div class="address-details">{{ item.sheng+item.shi+item.xian+item.address }}</div>
            </div>
          </div>
        </div>
      </div>
    </q-dialog>

  </div>
</template>

<script>
import { NavBar, Stepper, Cell, Toast } from 'vant';
import { ref } from 'vue';

export default {
  components: {
    [NavBar.name]: NavBar,
    [Stepper.name]: Stepper,
    [Cell.name]: Cell,
  },
  setup() {
    return {
      us: ref(null),
      order: ref({}),
      addressList: ref([]),
      address: ref({}),
      show: ref(false)
    }
  },
  created() {
    this.id = this.$route.query.id
    this.us = JSON.parse(this.$utils.getloc("us"))
    this.getShopLucky()
    this.getaddress()
  },
  methods: {
    onBack() {
      this.$router.back()
    },
    tolink(path) {
      this.$router.push(path)
    },
    getimg(img) {
			if (img != "") {
				return this.$config.send_url + "upload/" + img;
			}
		},
    // 幸运购详情
    getShopLucky() {
      let _this = this
      _this.$request.post(
        "api/ShopLuckyOrder/Get",
        {
          userid: _this.us.userid,
          luckyOrderId: _this.id
        },
        (res) => {
          if(res.data.code == 100) {
            let data = res.data.data
            _this.order = data
            // console.log(data)
          }
        }
      )
    },
    getaddress() {
      let _this = this
      _this.$request.post(
        "Api/UsersAddress/List",
        {
          userid: _this.us.userid,
        },
        (res) => {
          if (res.data.code == 100) {
            let data = res.data.data
            let address = data.find(item => item.isdefault == 1)
            if(!address && data.length > 0) {
              address = data[0]
            }
            _this.address = address
            _this.addressList = data
          }
        }
      )
    },
    cash() {
      let _this = this
      if(!_this.address) {
        Toast("请添加收货地址")
        return
      }
      this.$q.dialog({
				message: "确定要领奖吗？",
        cancel: true,
			}).onOk(() => {
        _this.$request.post(
          "api/ShopLuckyOrder/CashOrder",
          {
            
            userid: _this.us.userid,
            luckyOrderId: _this.id,
            addressId: _this.address.id
          },
          (res) => {
            _this.$q.dialog({
              message: res.data.msg,
              cancel: true,
            }).onOk(() => {
              if(res.data.code == 100) {
                _this.getShopLucky()
                _this.$router.push('shopOrder?state=1')
              }
            })
          }
        )
      })
    }
  }
}
</script>

<style scoped>
.pay {
  padding: 10px;
  min-height: 100vh;
  background: rgba(242, 242, 242, 1);
}

.goods {
  margin-top: 10px;
  padding: 10px;
  display: flex;
  border-radius: 10px;
  background-color: #fff;
}

.goods-img {
  width: 80px;
  border-radius: 10px;
}

.goods-content {
  flex: 1;
  margin-left: 13px;
}

.goods-title {
  font-size: 14px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.goods-label {
  font-size: 13px;
  color: rgba(153, 153, 153, 1);
}

.row-cell {
  padding: 10px 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid rgba(242, 242, 242, 1);
}

.btn {
  margin: 20px 0 0;
  height: 48px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #fff;
  background: linear-gradient(90deg, rgba(248, 18, 75, 1) 0%, rgba(254, 44, 45, 1) 100%);
}

.goods-price {
  color: #ff2d2e;
}

.address {
  padding: 5px 10px;
  border-radius: 10px;
  background-color: #fff;
}

.address-details {
  font-size: 12px;
  color: gray;
  display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	overflow: hidden;
}

.dialog {
  padding: 10px;
  background-color: #fff;
}

.dialog-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.disable-btn {
  opacity: .7;
}
</style>